import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import { decodeEntities } from '../utils/htmlParse';
import { RelatedPostsBlock } from '../acf/RelatedPostsBlock';
import SEO from '../components/SEO';
import Previewable from '../components/Previewable';
import '../acf/PageHeaderShort.scss';
import '../acf/PageNavigationBlock.scss';

const AcfComponent = ({ post, item, pathPrefix, location, wordpressUrl, componentName }) => {
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      post={post}
      pathPrefix={pathPrefix}
      location={location}
      wordpressUrl={wordpressUrl}
      {...item}
    />
  );
};

const VideoArticle = ({ data, location, pageContext }) => {
  const { wordpressWpResource: videoArticle, site, relatedPosts, allResourceTypes } = data;
  if (!videoArticle) return null;
  const {
    title,
    acf: {
      layout
    },
    yoast
  } = videoArticle;
  // const { previous, next } = pageContext;
  const { title: siteTitle, publicationsSlug, wordpressUrl } = site.siteMetadata;
  if (relatedPosts) {
    relatedPosts.edges.forEach((post, index) => {
      relatedPosts.edges[index].node.categories = post.node.resourceTypes.map(typeId => {
        const category = allResourceTypes.edges.find(({node: resourceType}) => resourceType.wordpress_id === typeId).node;
        return {
          name: category.name,
          slug: category.slug
        };
      })
    });
  }
  return (
    <Layout className="video-article-block">
      <SEO
        title={yoast.metaTitle ? `${decodeEntities(yoast.metaTitle)}` : `${decodeEntities(title)} | ${siteTitle}`}
        desc={yoast.metaDescription}
      />
      {layout && layout.map(item => {
        const layoutComponentName = item.name.replace('WordPressAcf_','');
        return (
          <AcfComponent
            post={videoArticle}
            pathPrefix={publicationsSlug}
            key={item.id}
            componentName={layoutComponentName}
            item={item}
            location={location}
            wordpressUrl={wordpressUrl}
          />
        );
      })}
      {relatedPosts && relatedPosts.edges && relatedPosts.edges > 0 && (
        <RelatedPostsBlock
          id="video-article-related-posts"
          title="Related"
          pathPrefix={publicationsSlug}
          posts={relatedPosts.edges}
        />
      )}
      {/* <section className="page-navigation-block">
        <div className="wrap">
          <div className="left-link">
            {previousPost && (
              <Link to={`/${blogSlug}/${previousPost.slug}/`}>
                <span className="link-label">Previous Article</span>
                {previousPost.title && <span className="headline">{previousPost.title}</span>}
              </Link>
            )}
          </div>
          <div className="right-link">
            {nextPost && (
              <Link to={`/${blogSlug}/${nextPost.slug}/`}>
                <span className="link-label">Next Article</span>
                {nextPost.title && <span className="headline">{nextPost.title}</span>}
              </Link>
            )}
          </div>
        </div>
      </section> */}
    </Layout>
  )
}

export default Previewable(VideoArticle)

export const pageQuery = graphql`
  fragment ResourceFields on wordpress__wp_resource {
    id
    slug
    content
    date(formatString: "DD.MM.YYYY")
    title
    excerpt
    author {
      name
    }
    featuredImage: featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
  query ResourceByID($id: String!, $primaryResourceTypeId: Int) {
    site {
      siteMetadata {
        title
        publicationsSlug
        wordpressUrl
        functionsUrl
      }
    }
    wordpressWpResource(id: { glob: $id }) {
      id
      title
      slug
      content
      date(formatString: "DD.MM.YYYY")
      author {
        name
        slug
      },
      resourceTypes: resource_type
      excerpt
      acf {
        layout: layout_resource {
          name: __typename
          ... on WordPressAcf_ArticleIntro {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            subtitle
            bodyText
            videoProvider
            videoUrl
          }
        }
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
    allResourceTypes: allWordpressWpResourceType(filter: { count: { gt: 0 } }) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    relatedPosts: allWordpressWpResource(filter: {resource_type: {eq: $primaryResourceTypeId}, id: {ne: $id}}) {
      edges {
        node {
          slug
          title
          excerpt
          date(formatString: "DD.MM.YYYY")
          author {
            name
          }
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          resourceTypes: resource_type
        }
      }
    }
  }
`
